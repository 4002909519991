@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@font-face {
  font-family: 'PP Neue Machina Regular';
  src: url('https://twynappsdata.blob.core.windows.net/messi-fonts/PPNeueMachina-PlainRegular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'PP Neue Machina Ultrabold';
  src: url('https://twynappsdata.blob.core.windows.net/messi-fonts/PPNeueMachina-PlainUltrabold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Manuka Condensed Black';
  src: url('https://twynappsdata.blob.core.windows.net/messi-fonts/manuka-condensed-black.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', 'PP Neue Machina Regular', 'PP Neue Machina Ultrabold', 'Manuka Condensed Black', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pclBody {
  text-transform: none;
  color: #000;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0;
}

#chatMessagesArea {
  background: black;
  min-height: calc(100vh - 260px);
}